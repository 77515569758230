import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JobRelatedFiles } from "./JobRelatedFiles";
import { Notes } from "./Notes";
import Images from "../images/Images";
import { MessageForm } from "../../features/emailMessageForm/components/MessageForm";
import { log } from "../../utils";
import PartsUsed from "./PartsUsed";
import { useDataContext } from "../../contexts/DataContext";
import GlassCollection from "./GlassCollection";
import BinReplacement from "./BinReplacement";
import BinDropAndRemoval from "./BinDropAndRemoval";
import BinHandleDropAndRemoval from "./BinHandleDropAndRemoval";
import { req } from "../../utils";
import { Grid } from "@mui/material";
import ProtectedImageGallery from "../images/ProtectedImageGallery";

const JobRelatedFilesMemoized = React.memo(JobRelatedFiles);

function JobAccordion({ job }) {
  const { jobs, venueLocations, machines } = useDataContext();
  const { service_call_id } = useParams();

  const [notesCount, setNotesCount] = useState(0);
  const [imagesCount, setImagesCount] = useState(0);
  const [filesCount, setFilesCount] = useState(0);
  const [binsCollectedCount, setBinsCollectedCount] = useState(0);
  const [imagesUploadArray, setImagesUploadArray] = useState([]);
  const [nbrCallImages, setNbrCallImages] = useState(0);
  const [safImages, setSafImages] = useState([]);
  const [needsBinCount, setNeedsBinCount] = useState(false);
  const [checkedForNeedsBinCount, setCheckedForNeedsBinCount] = useState(false);
  const [numberOfBins, setNumberOfBins] = useState(0);

  const checkNeedsBinCount = async () => {
    try {
      let venue = venueLocations.find(venue => String(venue.venue_id) === String(job.venue_id));
      console.log("venue", venue);
      if (
        String(venue?.is_warehouse) === "1"
      ) {
        setNeedsBinCount(false);
        setCheckedForNeedsBinCount(true);
        return;
      }
      if (checkedForNeedsBinCount) return;
      let nbrBins = await req(
        "GET",
        `needs_bin_count?venue_id=${job.service_venue_id}`
      );
      let needs_bin_count = nbrBins <= 0;

      setNeedsBinCount(needs_bin_count);
      setCheckedForNeedsBinCount(true);
    } catch (error) {
      log("Error in checkNeedsBinCount", error);
    }
  };

  const handleUpdateBinCount = async () => {
    try {
      let location = null; //try to get geo location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          location = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accuracy_in_meters: position.coords.accuracy,
          };
        });
      }
      await req("POST", `update_bin_count`, {
        service_call_id: job.service_call_id,
        qty_of_bins: numberOfBins,
        venue_id: job.service_venue_id,
        location,
      });
      setNeedsBinCount(false);
      alert("Bin count updated, thanks.");
    } catch (error) {
      log("Error in handleUpdateBinCount", error);
    }
  };

  useEffect(() => {
    try {
      checkNeedsBinCount();
    } catch (error) {
      log("Error in useEffect (checkNeedsBinCount)", error);
    }
  }, []);
  useEffect(() => {
    try {
        let nbr = job?.images_with_meta?.length ?? 0;
        nbr += safImages.length;
        setImagesCount(nbr);
;
    } catch (error) {
        log("Error in useEffect", error);
    }
    }, [job,safImages]);
  useEffect(() => {
    try {
      setNotesCount(job?.call_notes?.length ?? 0);
      setFilesCount(job?.files_for_technicians?.length ?? 0);
      setBinsCollectedCount(
        job.log
          ?.filter((item) => item.event === "bins_collected")
          .reduce(
            (acc, item) =>
              parseInt(acc) + (parseInt(item.details?.qty_of_bins) ?? 0),
            0
          ) ?? 0
      );

      const venueName =
        venueLocations.find(
          (venue) => String(venue.venue_id_fk) === String(job.venue_id)
        )?.venue_name || "Venue";

      // Ensure job.machine_ids_array is an array before mapping
      const machineImages = Array.isArray(job.machine_ids_array)
        ? job.machine_ids_array.map((machine_id) => {
            const machine = machines.find(
              (machine) => String(machine.machine_id_pk) === String(machine_id)
            );
            return {
              label: `Image for Machine ${machine?.serial_id}`,
              value: `machine_${machine_id}`,
            };
          })
        : [];

      setImagesUploadArray([
        {
          label: `Venue and machines`,
          value: `venue_and_machines`,
          selected: true,
        },
        { label: `For ${venueName}`, value: `venue_${job.venue_id}` },
        ...machineImages,
      ]);
    } catch (error) {
      log("Error in useEffect", error);
    }
  }, [jobs, service_call_id, venueLocations, machines, job]);

  if (!job) {
    return <div>No job data available.</div>;
  }

  return (
    <div>
      {needsBinCount && (
        <div style={{ marginBottom: "1rem" }}>
          <TextField
            fullWidth
            label="Number of Bins"
            type="number"
            color={"persiangreen"}
            size="small"
            value={numberOfBins}
            onChange={(e) => setNumberOfBins(e.target.value)}
            InputProps={{
              inputProps: {
                min: 0,
              },
              endAdornment: (
                <Button
                  variant="text"
                  color={"persiangreen"}
                  onClick={handleUpdateBinCount}
                >
                  Update bin count
                </Button>
              ),
            }}
          />
        </div>
      )}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>Notes ({notesCount})</div>
        </AccordionSummary>
        <AccordionDetails>
          <Notes job={job} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div>Images ({imagesCount})</div>
        </AccordionSummary>
        <AccordionDetails>
          {safImages.length > 0 && (
            <>
              <h5>Site Audit form Images</h5>
              <div>For more information click "Files"</div>
              <ProtectedImageGallery images={safImages} />
            </>
          )}
          <Images job={job} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <div>Files</div>
        </AccordionSummary>
        <AccordionDetails>
          <JobRelatedFilesMemoized
            setSafImages={setSafImages}
            setFilesCount={setFilesCount}
            job={job}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <div>Parts used</div>
        </AccordionSummary>
        <AccordionDetails>
          <PartsUsed job={job} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <div>Bins, Glass collection & Handles</div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item xs={6}>
              <h5>Glass</h5>
            </Grid>
            <Grid item xs={6}>
              <h5>Damaged bins</h5>
            </Grid>
            <Grid item xs={6}>
              <GlassCollection job={job} />
            </Grid>
            <Grid item xs={6}>
              <BinReplacement job={job} />
            </Grid>
            <Grid item xs={12}>
              <h5>Bins dropped off or removed</h5>
            </Grid>
            <Grid item xs={12}>
              <BinDropAndRemoval job={job} />
            </Grid>
            <Grid item xs={12}>
              <h5>Handles dropped off or removed</h5>
            </Grid>
            <Grid item xs={12}>
              <BinHandleDropAndRemoval job={job} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <div>Notify Account Rep</div>
        </AccordionSummary>
        <AccordionDetails>
          <MessageForm job={job} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default React.memo(JobAccordion);
