import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Snackbar, CircularProgress, MenuItem } from '@mui/material/';
import SendIcon from '@mui/icons-material/Send';
import { req, log } from '../../utils';
import { useDataContext } from '../../contexts/DataContext';

const BinHandleDropAndRemoval = ({ job }) => {
    const [qtyOfHandles, setQtyOfHandles] = useState(0);
    const { appendJobLog } = useDataContext();
    const [submitted, setSubmitted] = useState(false);
    const [buttonAction, setButtonAction] = useState('Added');
    const [handlesCollected, setHandlesCollected] = useState([]);
    const [defaultHandlesQty, setDefaultHandlesQty] = useState(0);
    const [snack, setSnack] = useState({
        open: false,
        message: '',
    });

    const handleQtyChange = (e) => {
        setQtyOfHandles(parseInt(e.target.value, 10));
    };

    // Updated to handle submission with or without geolocation data
    const handleSubmission = (lat = '0', long = '0', accuracy = '0') => {
        try {
            // Convert to negative if removing handles
            let qty_of_handles = buttonAction === 'Removed' ? -Math.abs(qtyOfHandles) : Math.abs(qtyOfHandles);
            
            const data = {
                venue_id: job.venue_id,
                service_call_id: job.service_call_id,
                qty_of_handles: qty_of_handles,
                lat: lat,
                long: long,
                accuracy_in_meters: accuracy,
            };
            
            setSubmitted(true);
            
            // Always use bin_handles_dropped endpoint
            req('post', 'bin_handles_dropped', data);
            
            appendJobLog(job.service_call_id, {
                event: "handles_dropped",
                time: Math.floor(Date.now() / 1000),
                details: {
                    qty_of_handles,
                    lat: lat,
                    long: long,
                    accuracy_in_meters: accuracy,
                }
            });
            
            setSnack({ open: true, message: 'Handles counted' });
            setQtyOfHandles(0);
            setDefaultHandlesQty(0);
            setSubmitted(false);
        } catch (e) {
            log("error in handleSubmission of BinHandleDropAndRemoval", e);
        };
    }

    const handleGeolocationSuccess = (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        handleSubmission(String(latitude), String(longitude), String(accuracy));
    };

    const handleGeolocationError = (error) => {
        console.warn(`ERROR(${error.code}): ${error.message}`);
        handleSubmission();
        setSnack({ open: true, message: 'Error obtaining location. Handles recorded without location data.' });
        logError('Error obtaining location', error);
    };

    const handleSubmit = () => {
        if (qtyOfHandles > 0) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            } else {
                handleSubmission();
                setSnack({ open: true, message: 'Geolocation is not supported by this browser. Handles recorded without location data.' });
            }
        } else {
            setSnack({ open: true, message: 'Please add quantity first!' });
        }
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({ ...snack, open: false });
    };

    const logError = (subject, details) => {
        log(subject, details);
    };

    useEffect(() => {
        if (job?.log && Array.isArray(job.log)) {
            setHandlesCollected(job.log.filter((log) => log.event === 'handles_dropped'));
        }
    }, [job]);

    // Add effect to fetch initial handle count needs
    useEffect(() => {
        const fetchHandleCount = async () => {
            try {
                const response = await req('get', 'needs_handle_count', {
                    venue_id: job.venue_id,
                    service_call_id: job.service_call_id
                });
                if (response && response.count) {
                    setDefaultHandlesQty(response.count);
                }
            } catch (e) {
                log("error fetching handle count", e);
            }
        };
        
        fetchHandleCount();
    }, [job]);

    return (<>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={8}>
                <TextField
                    autoFocus
                    id="qtyHandles"
                    name="qtyHandles"
                    type="number"
                    minval={0}
                    placeholder={`Nbr handles to be ${buttonAction.toLowerCase()}`}
                    label={`Nbr handles to be ${buttonAction.toLowerCase()}`}
                    fullWidth
                    color="persiangreen"
                    disabled={submitted}
                    onChange={handleQtyChange}
                    defaultValue={defaultHandlesQty}
                    InputProps={{
                        sx: {
                            height: '56px',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    id="handleStatus"
                    name="handleStatus"
                    label="Action"
                    fullWidth
                    color="persiangreen"
                    disabled={submitted}
                    defaultValue="Added"
                    onChange={(e) => setButtonAction(e.target.value)}
                    InputProps={{
                        sx: {
                            height: '56px',
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }
                    }}
                >
                    <MenuItem value="Added">Added</MenuItem>
                    <MenuItem value="Removed">Removed</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    className={submitted ? 'btn-submit btn-persiangreen-hover' : 'btn-submit'}
                    color="persiangreen"
                    disabled={submitted}
                    onClick={handleSubmit}
                    fullWidth
                    endIcon={submitted ? <CircularProgress size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} /> : <SendIcon size={16} style={{ marginLeft: '.5rem', marginTop: "-4px" }} />}
                >
                    {submitted ? `Recording handles ${buttonAction.toLowerCase()}` : `Record handles ${buttonAction.toLowerCase()}`}
                </Button>
            </Grid>
        </Grid>
        <div style={{ textAlign: 'left', display: 'block', marginTop: "1rem" }}>
            {handlesCollected.length === 0 ? null :
                (<div className='feedback-sent-title'>Handles dropped or removed</div>)}
            {handlesCollected.map((item) => {
                let action = "dropped off";
                let nbr = item.details.qty_of_handles;
                if (item.details.qty_of_handles < 0) {
                    action = "removed";
                    nbr = Math.abs(nbr); // absolute value
                }
                return (
                    <div className={"feedback"} key={item.time}>
                        <div className='message'>{nbr} {nbr > 1 ? 'handles' : 'handle'} {action}</div>
                        <div className='date'>{new Date(item.time * 1000).toLocaleString()}</div>
                    </div>
                )
            })}
        </div>

        <Snackbar
            open={snack.open}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            message={snack.message}
        />
    </>
    );
};

export default BinHandleDropAndRemoval; 