import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Rating,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  Alert,
  Box,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ImageUploader from "../ImageUploader";
import { SuggestedMessagesChips } from "../../features/emailMessageForm/components/SuggestedMessagesChips";
import { useDataContext } from "../../contexts/DataContext";
import { log } from "../../utils";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function CompleteJobMachineAssessment({
  job,
  machine,
  machine_id,
  updateMachineReview,
  requires_bottle_count,
  allow_no_review = false,
}) {

  const { technicians,machines } = useDataContext();
  const current_technician = localStorage.getItem("_bc_tech_user_id");
  const [nbrImagesAdded, setNbrImagesAdded] = useState(false);
  const [noReviewNeeded, setNoReviewNeeded] = useState(false);
  const [imageLoadedImages, setImageLoadedImages] = useState([]);
  const [suggestedMessages, setSuggestedMessages] = useState([]);
  const [thisMachine, setThisMachine] = useState({}); 
  const [servicedMachine, setServicedMachine] = useState(true); // [machine_id, review
  const messageRef = useRef(null); // Initialize the ref here
  const techs_for_tagging = [
    ...technicians,
    { account_id: -1, name: "The venue staff" },
  ];
  const defaultDate = (date) => {
    console.log('defaultDate', date)
    //if is a mysql date convert to dd/mm/yyyy
    if (date) {
      const d = new Date(date);
      //return as dd/mm/yyyy pad numbers under 10 with zero
      console.log('defaultDate', `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`)
      return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`
    } 
    return ''
  }

  //the assessment state variable
  const [state, setState] = useState({
    machine_id: machine_id, //static
    machine_number: machine?.serial_id || "",
    venue_id_fk: job.service_venue_id, //static
    service_call_id_fk: job.service_call_id, //static
    machine_rating: 0, //five star rating
    bottle_count: 0,
    previous_months_bottle_count: 0,
    latitude: job?.location?.latitude || 0,
    longitude: job?.location?.longitude || 0,
    accuracy: job?.location?.accuracy || 0,
    machine_inspection_completed: false,
    machine_cleaned: false,
    completion_notes: "",
    images: [],
    machine_tagged: true,
    machine_tagged_date: "", // ensure date fields are not undefined
    machine_tag: "",
    machine_tag_due_date: "",
    machine_tagged_by: current_technician || "", // ensure it defaults to an empty string if `current_technician` is undefined
    machine_stable: false,
    bin_full_height: false,
    fan_working: false,
    amp_reading: "",
    chisel_photo: null,
  });

  useEffect(() => {
    if (!thisMachine.machine_id_pk) {
      const foundMachine = machines.find(
        (machine) => String(machine.machine_id_pk) === String(machine_id)
      );
      if (foundMachine) {
        setThisMachine(foundMachine);
        //set state machine_number to the found machine serial_id
        console.log("foundMachine", foundMachine);
        setState(prev => ({
          ...prev,
          machine_number: foundMachine.serial_id
        }));
      }
    }
  }, [machine_id, machines, thisMachine]);

  useEffect(() => {
    if (machine?.serial_id) {
      setState(prev => ({
        ...prev,
        machine_number: machine.serial_id
      }));
    }
  }, [machine]);

  useEffect(() => {
    setSuggestedMessages([
      "Looked great.",
      "Should be serviced soon.",
      "Could use more machines.",
      "Venue should be evaluated.",
      "All good.",
    ]);
    //get the machhine in question from machine_id 

   

    setState((prevState) => ({
      ...prevState,
      machine_id: machine_id,
      venue_id_fk: job.service_venue_id,
      service_call_id_fk: job.service_call_id,
    }));
  }, [job,machine_id]);

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log("state changed", state);
    updateMachineReview(state);
  }, [state]);

  const handleSuggestionClick = (suggestion) => {
    try {
      //append to existing value
      messageRef.current.value += ` ${suggestion}`;
      handleStateChange("completion_notes", suggestion);
    } catch (error) {
      log("handleSuggestionClick", error);
    }
  };

  const handleUpload = (file) => {
    try {
      //if file is an array with one index then extract from array and add object to array
      if (Array.isArray(file) && file.length === 1) {
        file = file[0];
      }
      let images = [...state.images, file];
      handleStateChange("images", images);
      console.log("file uploaded", file, state.uploadedFiles);
    } catch (error) {
      log("handleUpload", error);
    }
  };

  const imagesFailed = (error) => {
    try {
      //console.log("!!!!!!!!!!!!!!!upload upload failed:", error);
      //since we needs to have a value in here for the state of 
      let images = [...state.images, 'error uploading image'];
      handleStateChange("images", images);
      log("imagesFailed", error);
    } catch (error) {
      log("imagesFailed", error);
    }
  };

  const toggleUploadingState = (isUploading) => {
    try {
      handleStateChange("waitForUpload", isUploading);
      updateMachineReview({ machine_id: machine, review: state });
    } catch (error) {
      log("toggleUploadingState", error);
    }
  };
  console.log("MACHINE", thisMachine);
console.log("state", state);
  return (
    <Grid container spacing={2}>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={servicedMachine}
                  onChange={(e) => {
                    try {
                      setNoReviewNeeded(e.target.checked);
                      if (e.target.checked) {
                        
                        updateMachineReview(state);
                        setServicedMachine(true);
                      } else {
                        updateMachineReview({machine_id: machine_id, not_serviced:true});
                        setServicedMachine(false);
                      }
                    } catch (error) {
                      log("allow_no_review", error);
                    }
                  }}
                />
              }
              label={(servicedMachine ? "Serviced machine" : "Machine not serviced")}
            />
          </Grid>
        </Grid>
              {servicedMachine && (
        <>
       
          <Grid item xs={6}>
            Machine rating
          </Grid>
          <Grid item xs={6}>
            <Rating
              name="hover-feedback"
              value={state.value}
              precision={0.5}
              onChange={(event, newValue) => {
                handleStateChange("machine_rating", newValue);
              }}
              onChangeActive={(event, newHover) => {
                //handleStateChange("hover", newHover);
              }}
              sx={{
                "& .MuiRating-iconFilled": { color: "persiangreen.main" }, // Your theme color
                "& .MuiRating-iconHover": { color: "persiangreen.dark" }, // Optional: Hover color
                "&& .MuiSvgIcon-root": { color: "inherit" }, // Override specific rule
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
          {requires_bottle_count.includes(job?.service_call_type) && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="bottleCount"
                  name="bottleCount"
                  label="Bottle Count"
                  size="small"
                  type="number"
                  fullWidth
                  required
                  onChange={(e) =>
                    handleStateChange("bottle_count", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="bottleCount"
                  name="bottleCount"
                  label="Previous Month's Bottle Count"
                  size="small"
                  type="number"
                  fullWidth
                  required
                  onChange={(e) =>
                    handleStateChange(
                      "previous_months_bottle_count",
                      e.target.value
                    )
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.fan_working}
                  onChange={(event) =>
                    handleStateChange(
                      "fan_working",
                      event.target.checked
                    )
                  }
                  color="persiangreen"
                />
              }
              label="Fan Working"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.machine_inspection_completed}
                  onChange={(event) =>
                    handleStateChange(
                      "machine_inspection_completed",
                      event.target.checked
                    )
                  }
                  color="persiangreen"
                />
              }
              label="Inspected"
            />
          </Grid>

          {/* Machine Selection Autocomplete with freeSolo */}
          <Grid item xs={12}>
            <TextField
              label="Machine Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={state.machine_number || ''}
              onChange={(event) => handleStateChange('machine_number', event.target.value)}
             
            />
          </Grid>
          
          {/* Tag Due Date */}
          <Grid item xs={12}>
            <TextField
              label="Tag Due Date"
              type="date"
              value={state.machine_tag_due_date || ''}
              onChange={(event) => handleStateChange('machine_tag_due_date', event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
           {/* Amp Reading */}
           <Grid item xs={12}>
            <TextField
              label="Amp Reading"
              variant="outlined"
              fullWidth
              margin="normal"
              value={state.amp_reading || ''}
              onChange={(event) => handleStateChange('amp_reading', event.target.value)}
         
            />
          </Grid>
          {/* Remaining checkboxes */}
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.machine_stable}
                  onChange={(event) =>
                    handleStateChange(
                      "machine_stable",
                      event.target.checked
                    )
                  }
                  color="persiangreen"
                />
              }
              label="Machine Stable"
            />
          </Grid>
          
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.bin_full_height}
                  onChange={(event) =>
                    handleStateChange(
                      "bin_full_height",
                      event.target.checked
                    )
                  }
                  color="persiangreen"
                />
              }
              label="Bin Full Height"
            />
          </Grid>
          
          {/* CLEANED checkbox moved to bottom */}
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.machine_cleaned}
                  onChange={(event) =>
                    handleStateChange(
                      "machine_cleaned",
                      event.target.checked
                    )
                  }
                  color="persiangreen"
                />
              }
              label="Cleaned"
            />
          </Grid>

          <Grid item xs={12}>
            {nbrImagesAdded && (
              <Alert sx={{ marginBottom: ".5rem;" }} severity="success">
                Image(s) added
              </Alert>
            )}
            <ImageUploader
              endpointUrl="upload"
              primaryUse="service_calls"
              primaryUseKey={job.service_call_id}
              otherFormData={null}
              successCallback={handleUpload}
              failureCallback={imagesFailed}
              uploadLabel="Chisel Photo"
            />
          </Grid>
          <Grid item xs={12}>
            {suggestedMessages && suggestedMessages.length > 0 && (
              <SuggestedMessagesChips
                suggestedMessages={suggestedMessages}
                handleSuggestionClick={handleSuggestionClick}
              />
            )}
            <TextField
              id="message"
              name="message"
              multiline
              placeholder="Tell us anything we should know.."
              fullWidth
              rows={2}
              color="persiangreen"
              onChange={(e) =>
                handleStateChange("completion_notes", e.target.value)
              }
              inputRef={messageRef}
            />
          </Grid>


          
         
        </>
              )}
    
    </Grid>
  );
}
